/* src/styles/markdown.css */
.markdown-content {
    font-size: 16px;
    line-height: 1.5;
}

.markdown-content h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.markdown-content h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 14px;
}

.markdown-content h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
}

.markdown-content p {
    margin-bottom: 16px;
}

.markdown-content a {
    text-decoration: underline;
    color: #3182ce;
    transition: color 0.2s;
}

.markdown-content a:hover {
    color: #2c5282;
}

.markdown-content ul, 
.markdown-content ol {
    margin-bottom: 16px;
    padding-left: 24px;
}

.markdown-content li {
    margin-bottom: 8px;
}

.markdown-content blockquote {
    border-left: 4px solid #cbd5e0;
    padding-left: 16px;
    margin-bottom: 16px;
}

.markdown-content img {
    max-width: 100%;
    margin: 16px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.markdown-content code {
    /* background-color: #304152; */
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
}

.markdown-content pre {
    /* background-color: #edf2f7; */
    padding: 16px;
    border-radius: 4px;
    overflow-x: auto;
}

.markdown-content pre code {
    background: none;
    padding: 0;
}
